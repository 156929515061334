.fadeSwitch-enter{
  opacity: 0;
}
.fadeSwitch-exit{
  opacity: 1;
}
.fadeSwitch-enter-active{
  opacity: 1;
}
.fadeSwitch-exit-active{
  opacity: 0;
}
.fadeSwitch-enter-active,
.fadeSwitch-exit-active{
  transition: opacity 0.5s;
}
